import axiosClient from "../axiosClient";

export async function bulkDeleteProductDB(ids) {
  return await axiosClient.post(`products/bulk_delete`, ids);
}

export async function retrieveProductsData() {
  return await axiosClient.get("/products");
}

export async function retrieveProductsByFkDB(value) {
  console.log("retrieveProductsByFkDB", value);
  var body = {
    p_buyer: value?.order_no,
  };
  return await axiosClient.post("/pre_costing_headers/get_style", body);
}

export async function addProductDB(value) {
  return await axiosClient.post("/products", value);
}

export async function deleteProductDB(id) {
  return await axiosClient.delete(`/products/${id}`);
}

export async function editProductDB(value) {
  return await axiosClient.put(`/products/${value.id}`, value);
}
