import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./suxes-sm.jpeg";
import { CurrencyToWords } from "currency-to-words";

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const currencyName = invoiceData && invoiceData.length ? invoiceData[2] : {};
  const tableBodyData = invoiceData && invoiceData.length ? invoiceData[1] : {};

  const HeaderData = invoiceData && invoiceData.length ? invoiceData[0] : {};

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];

  const HeaderDataRefine = HeaderData
    ? Object.keys(HeaderData).map((key) => HeaderData[key])
    : [];

  const totalOrderQty =
    tableBodyDataRefine &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.order_qty),
      0
    );
  // totalFobValue
  const totalamount =
    tableBodyDataRefine &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.amount),
      0
    );

  const totalValueInword = CurrencyToWords(
    totalamount ? Number(totalamount).toFixed(2) : 0
  );

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  console.log("invoiceData", invoiceData);

  let count = 0;
  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "70px",
            borderBottom: "2px",
          }}
        >
          <View style={{ width: "20%", height: "70px", textAlign: "left" }}>
            <Image
              style={{
                width: "70px",
                height: "70px",
              }}
              src={logo}
            />
          </View>
          <View
            style={{
              width: "60%",
              height: "70px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 24, fontWeight: "bold" }}>
              SUXES ATTIRES LTD.
            </Text>
            <Text
              style={{
                fontSize: 10,
                paddingTop: 3,
                paddingBottom: 4,
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              House #2, Road8/A, Nikunja-1, Khilkhet, Dhaka-1229.
            </Text>
          </View>
        </View>
        <View
          style={{
            height: "40px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              fontFamily: "Arial",
              color: "black",
              padding: "6px",
              borderBottom: "1px",
            }}
          >
            PROFORMA INVOICE
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20px",
            border: "1px",
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: "black",
                fontSize: 8,
                fontWeight: "bold",
                marginLeft: 5,
              }}
            >
              PROFORMA INVOICE NO :{" "}
              {HeaderDataRefine &&
                HeaderDataRefine.length &&
                HeaderDataRefine[0]?.dm_order_no}
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "center",
              borderLeft: "1px",
              alignItems: "flex-end",
            }}
          >
            <Text
              style={{
                color: "black",
                fontSize: 8,
                fontWeight: "bold",
                marginRight: 5,
              }}
            >
              ISSUE DATED :{" "}
              {HeaderDataRefine &&
                HeaderDataRefine.length &&
                HeaderDataRefine[0]?.order_date}
            </Text>
          </View>
        </View>
        {/* Header Info */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "90px",
            border: "1px",
            marginTop: 10,
          }}
        >
          <View
            style={{
              width: "50%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              padding: 5,
            }}
          >
            <Text
              style={{
                color: "black",
                fontSize: 9,
                fontWeight: "bold",
                borderBottom: "1px",
              }}
            >
              BUYER / APPLICANT :
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              {HeaderDataRefine &&
                HeaderDataRefine.length &&
                HeaderDataRefine[0]?.buyer_name}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                fontWeight: "bold",
              }}
            >
              {HeaderDataRefine &&
                HeaderDataRefine.length &&
                HeaderDataRefine[0]?.address}
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "flex-start",
              borderLeft: "1px",
              alignItems: "flex-start",
              padding: 5,
            }}
          >
            <Text
              style={{
                color: "black",
                fontSize: 9,
                fontWeight: "bold",
                borderBottom: "1px",
              }}
            >
              BENEFICIARY:{" "}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              SUXES ATTIRES LTD.
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                fontWeight: "bold",
              }}
            >
              HOUSE #02, ROAD # 8/A, NIKUNJA 1, {"\n"} KHILKHET,
              DHAKA-1229,BANGLADESH.
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              Contact Person : Sumon Faruque
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              Cell : +88 01713010677
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              Email : sumon@suxes-attires.com
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              justifyContent: "flex-start",
              borderLeft: "1px",
              alignItems: "flex-start",
              padding: 5,
            }}
          >
            <Text
              style={{
                color: "black",
                fontSize: 9,
                fontWeight: "bold",
                borderBottom: "1px",
              }}
            >
              BENEFICIARY BANK DETAILS:{" "}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              {HeaderDataRefine &&
                HeaderDataRefine.length &&
                HeaderDataRefine[0]?.dm_bank}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              {HeaderDataRefine &&
                HeaderDataRefine.length &&
                HeaderDataRefine[0]?.dm_bakaddress}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              SWIFT CODE :{" "}
              {HeaderDataRefine &&
                HeaderDataRefine.length &&
                HeaderDataRefine[0]?.dm_swift_code}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: 8,
                marginTop: 2,
                fontWeight: "bold",
              }}
            >
              A/C NO :{" "}
              {HeaderDataRefine &&
                HeaderDataRefine.length &&
                HeaderDataRefine[0]?.dm_acno}
            </Text>
          </View>
        </View>
        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
            }}
            fixed
          >
            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                fontSize: "8px",
                fontFamily: "Arial",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              S.L NO.
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "15%",
                fontSize: "8px",
                fontFamily: "Arial",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              ORDER NO.
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "15%",
                fontFamily: "Arial",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              STYLE NO
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "20%",
                fontFamily: "Arial",
                fontWeight: "bold",
                borderRight: "1px solid black",
                fontSize: "8px",
                textAlign: "center",
              }}
            >
              DESCRIPTION OF GOODS
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "15%",
                fontFamily: "Arial",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              QUANTITY {"\n"} IN PCS
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "10%",
                fontFamily: "Arial",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              UNIT PRICE {"\n"} (FOB)
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "15%",
                fontFamily: "Arial",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              TOTAL VALUE {"\n"} IN USD
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "15%",
                fontFamily: "Arial",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              SHIPMENT DATE
            </Text>
          </View>
          <View style={{}}>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => {
                count = count + 1;
                return (
                  <View
                    key={index}
                    wrap={false}
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "8px",
                        height: "auto",
                        width: "10%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "8px",
                        height: "auto",
                        width: "15%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {item?.order_no}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "8px",
                        height: "auto",
                        width: "15%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {item?.style}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "8px",
                        height: "auto",
                        width: "20%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {item?.product}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "8px",
                        height: "auto",
                        width: "15%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {item?.order_qty}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "8px",
                        height: "auto",
                        width: "10%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      $ {Number(item?.unit_price).toFixed(2)}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "8px",
                        height: "auto",
                        width: "15%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      $ {Number(item?.amount).toFixed(2)}
                    </Text>
                    <Text
                      style={{
                        padding: "5px",
                        fontSize: "8px",
                        height: "auto",
                        width: "15%",
                        textAlign: "center",
                      }}
                    >
                      {item?.ship_date}
                    </Text>
                  </View>
                );
              })}
            <View
              wrap={false}
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  fontFamily: "Arial",
                  fontWeight: "bold",
                  width: "60%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                GRAND TOTAL=
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontFamily: "Arial",
                  fontWeight: "bold",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalOrderQty} PCS.
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontFamily: "Arial",
                  fontWeight: "bold",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                $ {Number(totalamount).toFixed(2)}{" "}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
            </View>
          </View>
          <View>
            <Text
              style={{
                padding: "5px",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              TOTAL U. S. DOLLARS{" "}
              <Text style={{ fontSize: "10px", fontWeight: "bold" }}>
                {totalValueInword?.replace(/dollars/g, "").toUpperCase()} ONLY.
              </Text>
            </Text>
          </View>
        </View>

        <View
          style={{ display: "flex", flexDirection: "column", marginTop: 10 }}
          break={count > 8 ? true : false}
          wrap={false}
        >
          <Text
            style={{
              color: "black",
              fontSize: 9,
              fontWeight: "bold",
              borderBottom: "1px",
              width: "110px",
            }}
          >
            TERMS & CONDITIONS :
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10px",
              marginTop: 5,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (01).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                DOCUMENTTARY CREDIT
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  {HeaderDataRefine &&
                    HeaderDataRefine.length &&
                    HeaderDataRefine[0]?.term_name}{" "}
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (02).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                PAYMENT TERMS
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  {HeaderDataRefine &&
                    HeaderDataRefine.length &&
                    HeaderDataRefine[0]?.term_name}{" "}
                  {HeaderDataRefine &&
                    HeaderDataRefine.length &&
                    HeaderDataRefine[0]?.term_details}
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (03).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                DELIVERY TERMS
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  FOB
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (04).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                PORT OF LOADING
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  CHITTAGONG
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (05).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                TRANSHIPMENT
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  ALLOWED
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (06).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                PARTIAL SHIPMENT
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  ALLOWED
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (07).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                NEGOTIATION BANK
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  ANY BANK IN BANGLADESH
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (08).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                EXPIRY DATE
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                ></Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              minHeight: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (09).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                REIMBURSEMENT CLAUSE
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  AFTER RECEIVED THE DOCUMENTS OPENING BANK SHALL CONFIRM FOR
                  THE PAYMENT AND PAYMENT SHALL BE MADE BY ORBEFORE OR AS
                  INSTRUCTION OF NEGOTITION.
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              minHeight: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (10).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                B/L CLAUSE
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                  }}
                >
                  FULL SET OF CLEAN ON BOARD MARINE/OCEAN BILL OF LADING
                  CONSIGNED TO THE ORDER OF NEGOTIATING BANK IN BANGLADESH. AND
                  ENDORSED TO THE ORDER OF L/C OPENING BANK AND NOTIFY APPLICANT
                  MAKED{" "}
                  <Text
                    style={{
                      color: "black",
                      fontSize: 8,
                      fontWeight: "bold",
                    }}
                  >
                    "FREIGHT COLLECT."
                  </Text>
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              minHeight: "10px",
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: "5%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                (11).
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                BENEFICIARY BANK / ADVISING {"\n"} BANK / TRANSFERING BANK
              </Text>
            </View>

            <View
              style={{
                width: "65%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 8,
                  marginRight: 5,
                }}
              >
                :{" "}
                <Text
                  style={{
                    color: "black",
                    fontSize: 8,
                    marginLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {HeaderDataRefine &&
                    HeaderDataRefine.length &&
                    HeaderDataRefine[0]?.dm_bank}{" "}
                  {"\n"}{" "}
                  <Text
                    style={{
                      color: "black",
                      fontSize: 8,
                      marginLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {HeaderDataRefine &&
                      HeaderDataRefine.length &&
                      HeaderDataRefine[0]?.dm_bakaddress}
                  </Text>{" "}
                  {"\n"}{" "}
                  <Text
                    style={{
                      color: "black",
                      fontSize: 8,
                      marginLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    SWIFT CODE :{" "}
                    {HeaderDataRefine &&
                      HeaderDataRefine.length &&
                      HeaderDataRefine[0]?.dm_swift_code}
                  </Text>
                  {"\n"}{" "}
                  <Text
                    style={{
                      color: "black",
                      fontSize: 8,
                      marginLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    A/C NO :{" "}
                    {HeaderDataRefine &&
                      HeaderDataRefine.length &&
                      HeaderDataRefine[0]?.dm_acno}
                  </Text>
                </Text>
              </Text>
            </View>
          </View>
        </View>
        {/* Signature part */}
        <View
          wrap={false}
          style={{
            flexDirection: "row",
            marginTop: "60px",
          }}
        >
          <View
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                backgroundColor: "black",
                height: "1px",
                width: "150px",
              }}
            ></Text>
            <Text
              style={{
                fontSize: "11px",
                width: "150px",
                marginTop: 5,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              FOR, SUXES ATTIRES LTD
            </Text>
            <Text
              style={{
                fontSize: "11px",
                width: "150px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              AUTHORIZED SIGNATURE
            </Text>
          </View>
        </View>
        {/* TOTAL CALCULATE PART */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    paddingHorizontal: 40,
    fontFamily: "Arial",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
