import React from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import { loginAction } from "../../redux/actions/auth/authActions";

const Login = ({ location, history }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = React.useState(false);

  const loginInfo = useSelector((state) => state?.loginInfoList?.loginInfo);

  const { loading, error } = useSelector((state) => state?.loginInfoList);

  const redirect = location.search ? location.search.split("=")[1] : "/app";

  React.useEffect(() => {
    if (loginInfo) {
      history.push(redirect);
      console.log("redirect", redirect);
    } else if (error) {
      setLoading(false);
    }
  }, [history, loginInfo, error, redirect]);

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    dispatch(
      loginAction({
        user_id: uname.value,
        password: pass.value,
      })
    );
  };

  return (
    <div class="newbody">
      <div class="wrapper">
        <form action="" onSubmit={handleSubmit}>
          <h1>Login</h1>
          <div class="input-box">
            <input type="text" placeholder="Username" name="uname" required />
            <i class="bx bxs-user"></i>
          </div>
          <div class="input-box">
            <input
              type="password"
              placeholder="Password"
              name="pass"
              required
            />
            <i class="bx bxs-lock-alt"></i>
          </div>
          <div class="remember-forgot">
            <label>
              <input type="checkbox" />
              Remember Me
            </label>
            {/* <a href="#">Forgot Password</a> */}
          </div>
          <button type="submit" class="btn">
            {loading && <CircularProgress size={25} color="inherit" />}
            {!loading && "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
